import { useDispatch } from 'react-redux';

import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { AppID, SpaceID } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { kbarToolkit } from '/store';

import { spacesServiceQueryKeys, updateSpaceMutationKey } from '../spacesService';

import { appsQueryTags, IApps, ICurrentApp, updateAppMutationKey } from './constants';

export const useAddFavourite = (
  currentApp: ICurrentApp,
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, { app: IApps }>
) => {
  const dispatch = useDispatch();

  const isSpaceApp = currentApp === ICurrentApp.SPACE;
  return useEitherMutation(
    ({ app }) => {
      const isApp = isSpaceApp
        ? services2.spacesService.addFavourite(app.id as SpaceID)
        : services2.appsService.addFavourite(app.id as AppID);
      return isApp;
    },
    {
      mutationKey: isSpaceApp ? updateSpaceMutationKey : updateAppMutationKey,
      removeQueriesKeys: isSpaceApp ? spacesServiceQueryKeys : appsQueryTags,
      onSuccess: (_, { app }) => {
        snackbarService.success(`${app.name} has been added to the top of app list`);
        dispatch(kbarToolkit.actions.setShouldUpdateApps(true));
      },
      ...options,
    }
  );
};
